"use strict";

// JavaScript Document
function setHeight() {
  var vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", "".concat(vh, "px"));
}

setHeight();
window.addEventListener("resize", setHeight);
/* 垂直スライダーの設定 */

var verticalSlider = new Swiper(".js-fullScreenFlexSwiper__vertical", {
  loop: false,
  direction: "vertical",
  slidesPerView: 1,
  speed: 800,
  mousewheel: {
    forceToAxis: true,
    invert: true,
    releaseOnEdges: true,
    eventsTarget: '#visualPanels'
  },
  pagination: {
    el: ".Vswiper-pagination",
    type: "bullets",
    clickable: true //verticalClass:'Vbullet',

  },
  on: {
    // 	// beforeInit:function(){
    // 	// 	loginFlg();
    // 	// },
    afterInit: function afterInit() {
      swiper.emit();
    },
    toEdge: function toEdge() {
      setTimeout(function () {
        console.log('ok');
        verticalSlider.params.mousewheel.releaseOnEdges = true;
        verticalSlider.params.touchReleaseOnEdges = true; //verticalSlider.mousewheel.disable();

        console.log('disabled');
      }, 200);
    },
    fromEdge: function fromEdge() {
      setTimeout(function () {
        verticalSlider.params.mousewheel.releaseOnEdges = false;
        verticalSlider.params.touchReleaseOnEdges = false; //verticalSlider.mousewheel.enable();

        console.log('enabled');
      }, 200);
    } // reachEnd:function(){
    //     verticalSlider.params.mousewheel.releaseOnEdges=true;
    // 	verticalSlider.params.touchReleaseOnEdges=true;
    //     console.log('解放');
    // }

  }
}); // $(function(){
// 	$(window).on('scroll load resize',function(){
// 		var scrollTop = $(this).scrollTop();
// 		//console.log('scrollTop:'+scrollTop);
// 		if(scrollTop==0){
// 			$('#panelInner').removeClass('scrolloff');
// 		}else{
// 			$('#panelInner').addClass('scrolloff');
// 		}
// 	});
// });	

/* 水平スライダーの設定 */

var horizontalSlider = new Swiper(".js-fullScreenFlexSwiper__horizon", {
  loop: false,
  direction: "horizontal",
  slidesPerView: 1,
  speed: 800,
  //nested:true,
  mousewheel: {
    forceToAxis: true
  },
  pagination: {
    el: ".swiper-pagination",
    //type: "bullets",
    type: "fraction",
    clickable: true
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  }
}); //スムーススクロール（高さ）

$(function () {
  // #で始まるリンクをクリックした場合
  $('.js-moveToNext').click(function () {
    // スクロールの速度
    var speed = 400; // スクロールタイプ

    var type = 'swing'; // href属性の取得

    var href = $(this).attr("href"); // 移動先の取得（hrefが#indexならトップ$(html)に、）

    var target = $(href == "#index" ? 'html' : href); // 移動先のポジション取得
    //let position = target.offset().top;

    var viewHeight = $(window).innerHeight(); //console.log("viewHeight:"+viewHeight);
    // animateでスムーススクロール   

    $('body,html').animate({
      scrollTop: viewHeight
    }, speed, type);
    return false;
  });
});